<template>
  <div>
    <div
      class="d-flex justify-between"
    >
      <div class="d-flex">
        <h4 class="text-black mt-50">
          <strong>
            Riwayat Perjalanan
          </strong>
        </h4>
        <img
          :src="shippingImage"
          class="w-16 mx-1"
        >
      </div>
      <img
        v-if="$route.name !== 'tiket-detail-kolaborator'"
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close-circle.svg"
        type="button"
        @click="$bvModal.hide('tracking-resi')"
      >
    </div>
    <b-row
      class=""
      style="overflow-y: scroll; height: 50vh;"
    >
      <div
        v-if="itemAwb.length > 0"
      >
        <b-row
          v-for="(item, index) in itemAwb"
          :key="index"
          class="my-1"
        >
          <b-col
            cols="2"
            class="ml-1"
            style="text-align: -webkit-center"
          >
            <img
              :src="iconAwb(item)"
              alt="icon-shipping"
            >
            <div
              v-if="index !== itemAwb.length - 1"
              class="border-dashed border-2 border-[#FBA63C] d-inline-block h-100"
            />
          </b-col>
          <b-col
            cols="9"
            class="d-inline-block"
          >
            <div>{{ FULL_DATE_TIME(item.date) }}</div>
            <div
              class=""
            >
              <div class="font-bold">
                {{ item.desc }}
              </div>
              <div v-if="item.send_wa === 1">
                <div
                  class="d-flex relative p-1 mx-1 border-2 bordered-[#E2E2E2] rounded w-50"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/svg/WA-Premium.svg"
                    alt="wa-premium"
                  >
                  <span
                    v-if="item.type === 'sending'"
                    class="my-auto ml-1"
                  >Pemberitahuan pemberangkatan telah terkirim ke WA Pelanggan</span>
                  <span
                    v-if="item.type === 'arrived'"
                    class="my-auto ml-1"
                  >Info paket COD hampir sampai telah terkirim ke WA Pelanggan</span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-col v-if="itemAwb.length === 0">
        <div
          v-if="isLoading===false"
          class="d-block mt-5 mb-5 align-content-center text-center"
        >
          Data riwayat perjalan tidak ditemukan. <b>Bisa jadi</b> sudah request pickup/dijemput kurir saat pickup namun <b>belum discan</b> QR code untuk memulai perjalanan di kantor cabang. Harap menunggu
        </div>
        <div
          v-if="isLoading===true"
          class="d-block mt-5 mb-5 align-content-center text-center"
        >
          <div
            class="spinner-border text-primary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </b-col>
    </b-row>

    <div v-if="isShowClaimMessage">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { FULL_DATE_TIME } from '@/libs/filterDate'
import { mounted } from 'vue-echarts'

export default {
  props: {
    awb: {
      type: String,
      default: '',
    },
    shipping: {
      type: String,
      default: '',
    },
    shippingImage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      listAwb: '',
      itemAwb: [],
      FULL_DATE_TIME,
      isShowClaimMessage: false,
      dataDetailTiket: {},
    }
  },
  async created() {
    await this.getHistoryPackage()
  },
  methods: {
    async getHistoryPackage() {
      const params = {
        airway_bill: this.awb,
        shipping: this.shipping,
      }
      await this.$http_collaborator.get('/order/api/v1/orders/admin/data-airway-bill', { params }).then(res => {
        const { data } = res.data
        this.itemAwb = data.history
        this.shippingImage = data.shipping_logo
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
      })
    },
    iconAwb(items) {
      let result = ''
      if (items.status === 'Pickup') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-pickup.svg'
      } else if (items.status === 'Process') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-kirim.svg'
      } else if (items.status === 'Problem') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-problem.svg'
      } else if (items.status === 'Delivered') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-terima.svg'
      } else if (items.status === 'Retur') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-retur.svg'
      } else if (items.status === 'Delivery') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-delivery.svg'
      }
      return result
    },
  },
}
</script>
